import * as React from 'react';
import { useMediaQuery } from 'react-responsive';
import { isDesktopOrLaptopQuery } from '../constants/MediaQueries';
import { DatePickerMobile } from './DatePickerMobile';
import { DatePickerProps } from '../types/DatePickerProps';
import { useSelector } from '../../redux/store';
import { getFieldError } from '../../redux/notification/notificationsSelectors';

export const DatePicker: React.FC<DatePickerProps> = ({
  title,
  value,
  options,
  updateHandler,
  blurHandler,
  fieldName,
}: DatePickerProps) => {
  const [datePickerDesktop, setDatePickerDesktop] =
    React.useState<React.ReactElement | null>(null);

  const isDesktopOrLaptop = useMediaQuery({
    query: isDesktopOrLaptopQuery,
  });

  const error = useSelector(getFieldError(fieldName));

  if (datePickerDesktop) {
    return datePickerDesktop;
  }

  if (isDesktopOrLaptop) {
    import('./DatePickerDesktop').then(({ DatePickerDesktop }) =>
      setDatePickerDesktop(
        <DatePickerDesktop
          title={title}
          value={value}
          options={options}
          fieldName={fieldName}
          updateHandler={updateHandler}
          blurHandler={blurHandler}
        />
      )
    );
  }

  return (
    <DatePickerMobile
      title={title}
      error={error}
      value={value}
      options={options}
      fieldName={fieldName}
      updateHandler={updateHandler}
      blurHandler={blurHandler}
    />
  );
};
