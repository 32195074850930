import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';
import { Action } from 'redux';
import { LoadingStatus } from '../types';

export enum ActionType {
  SET_AUTH_TOKENS = 'AUTH/SET_AUTH_TOKENS',
  SET_LOADING_STATUS = 'AUTH/SET_LOADING_STATUS',
  SET_IS_REDIRECTING = 'AUTH/SET_IS_REDIRECTING',
  SET_ACTUAL_REGISTRATION_INFO = 'AUTH/SET_ACTUAL_REGISTRATION_INFO',
}

export interface SetAuthTokens extends Action {
  type: ActionType.SET_AUTH_TOKENS;
  refreshToken: string | null;
  accessToken: string | null;
}

export const setAuthTokens = (
  refreshToken: string | null,
  accessToken: string | null
): SetAuthTokens => ({
  type: ActionType.SET_AUTH_TOKENS,
  refreshToken,
  accessToken,
});

export interface SetLoadingStatus extends Action {
  type: ActionType.SET_LOADING_STATUS;
  value: LoadingStatus;
}

export const setLoadingStatus = (value: LoadingStatus): SetLoadingStatus => ({
  type: ActionType.SET_LOADING_STATUS,
  value,
});

export interface SetIsRedirecting extends Action {
  type: ActionType.SET_IS_REDIRECTING;
  value: boolean;
}

export const setIsRedirecting = (value: boolean): SetIsRedirecting => ({
  type: ActionType.SET_IS_REDIRECTING,
  value,
});

export interface SetActualRegistrationInfo extends Action {
  type: ActionType.SET_ACTUAL_REGISTRATION_INFO;
  hash: string;
  status: DriverRegistrationPortalNoAuthService.DriverRegistrationLogStatus;
}

export const setActualRegistrationInfo = (
  hash: string,
  status: DriverRegistrationPortalNoAuthService.DriverRegistrationLogStatus
): SetActualRegistrationInfo => ({
  type: ActionType.SET_ACTUAL_REGISTRATION_INFO,
  hash,
  status,
});

export type AuthAction =
  | SetAuthTokens
  | SetLoadingStatus
  | SetIsRedirecting
  | SetActualRegistrationInfo;
