import React, { ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';
import { useSelector } from '../../redux/store';
import {
  changeLanguage,
  initLanguages,
} from '../../redux/localization/localizationThunk';
import {
  getIsCountryFinal,
  getLanguage,
  getLanguageLoadingStatus,
  getLanguages,
} from '../../redux/localization/localizationSelectors';
import { getLoadingStatus } from '../../redux/auth/authSelectors';
import { LanguageLoadingStatus, LoadingStatus } from '../../redux/types';
import { setLanguageLoadingStatus } from '../../redux/localization/localizationActions';
import {
  isVerificationLoading,
  isCountryIsKnown,
} from '../../redux/verification/verificationSelectors';

const GlobeIcon = (): ReactElement => (
  <svg
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.50008 0.666656C3.91675 0.666656 0.166748 4.41666 0.166748 8.99999C0.166748 13.5833 3.91675 17.3333 8.50008 17.3333C13.0834 17.3333 16.8334 13.5833 16.8334 8.99999C16.8334 4.41666 13.0834 0.666656 8.50008 0.666656ZM3.50008 8.16666C3.50008 9.83332 6.00008 11.5 5.16675 13.1667C4.91675 13.6667 4.58342 14 4.16675 14.0833C2.75008 12.8333 1.83341 11.0833 1.83341 8.99999C1.83341 6.49999 3.25008 4.33332 5.25008 3.16666C5.66675 3.58332 6.00008 4.08332 6.00008 4.83332C6.00008 7.33332 3.50008 6.49999 3.50008 8.16666ZM13.1667 13.75C12.1667 12.5 13.0834 10.6667 12.6667 9.83332C11.8334 8.16666 9.33342 9.83332 9.33342 7.33332C9.33342 5.58332 10.8334 4.33332 12.2501 3.49999C14.0001 4.74999 15.1667 6.74999 15.1667 8.99999C15.1667 10.8333 14.4167 12.5 13.1667 13.75Z"
      fill="#2F313F"
    />
  </svg>
);

const LanguageSelector = (): React.ReactElement => {
  const dispatch = useDispatch();
  const languages: DriverRegistrationPortalNoAuthService.FlowLanguage[] =
    useSelector(getLanguages);
  const formLoadingStatus = useSelector(getLoadingStatus);
  const isCountryFinal = useSelector(getIsCountryFinal);
  const languageLoadingStatus = useSelector(getLanguageLoadingStatus);
  const isVerificationLoaded = !useSelector(isVerificationLoading);
  const isVerificationCountryKnown = useSelector(isCountryIsKnown);

  React.useEffect(() => {
    if (languageLoadingStatus !== LanguageLoadingStatus.NOT_STARTED) {
      return;
    }
    if (
      isCountryFinal ||
      formLoadingStatus !== LoadingStatus.LOADING ||
      isVerificationLoaded ||
      isVerificationCountryKnown
    ) {
      dispatch(setLanguageLoadingStatus(LanguageLoadingStatus.LOADING));
      dispatch(initLanguages());
    }
  }, [
    dispatch,
    isCountryFinal,
    formLoadingStatus,
    languageLoadingStatus,
    isVerificationLoaded,
    isVerificationCountryKnown,
  ]);

  const language = useSelector(getLanguage);

  if (!language || !languages) {
    return <span id="language_select" />;
  }

  return (
    <div className="text-center pb-6 pt-6 mt-6 border-t border-gray-300">
      <div className="flex flex-row justify-center">
        <div className="flex flex-row content-center items-center pl-4 pr-3 py-1 bg-gray-200 rounded-full relative">
          <GlobeIcon />
          <select
            id="language_select"
            onChange={({ target: { value } }) => {
              return dispatch(changeLanguage(value));
            }}
            value={language}
            className="bg-gray-200 appearance-none outline-none border-none px-2 py-1"
          >
            {languages.map((c) => (
              <option key={c.key} value={c.key}>
                {c.name}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default LanguageSelector;
