import { ActualRegistrationInfo, IAppState, LoadingStatus } from '../types';

export const getRefreshToken = (state: IAppState): string | null =>
  state.auth.refreshToken;

export const getAccessToken = (state: IAppState): string | null =>
  state.auth.accessToken;

export const getLoadingStatus = (state: IAppState): LoadingStatus => {
  if (state.auth.isRedirecting) {
    return LoadingStatus.LOADING;
  }
  return state.auth.loadingStatus;
};

export const getHasPartnerAccount = (state: IAppState): boolean =>
  Boolean(state.auth.refreshToken);

export const getActualRegistrationInfo = (
  state: IAppState
): ActualRegistrationInfo | null => state.auth.actualRegistrationInfo;
