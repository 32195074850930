export class ResponseCodes {
  public static readonly SUCCESS = 0;

  public static readonly DRIVER_REGISTRATION_FORM_VALIDATION_FAILED = 61907;

  public static readonly DRIVER_PROVISIONAL_REGISTRATION_NOT_FOUND = 78285;

  public static readonly DRIVER_PROVISIONAL_REGISTRATION_ALREADY_VERIFIED = 61909;

  public static readonly DRIVER_PROVISIONAL_REGISTRATION_OTP_NOT_FOUND = 61910;

  public static readonly DRIVER_PROVISIONAL_REGISTRATION_OTP_EXPIRED = 61911;

  public static readonly DRIVER_PROVISIONAL_REGISTRATION_OTP_USED = 61912;

  public static readonly DRIVER_PROVISIONAL_REGISTRATION_OTP_NOT_SENT = 61913;

  public static readonly DUPLICATE_PARTNER_FOUND = 61914;

  public static readonly DUPLICATE_PARTNER_FOUND_WITH_PROMPT = 61917;

  public static readonly PARTNER_ALREADY_HAS_DRIVER = 61915;

  public static readonly TRY_AGAIN_LATER = 78287;

  public static readonly SENDING_SMS_FAILED = 78288;

  public static readonly DAILY_LIMIT_REACHED = 78289;

  public static readonly INVALID_REQUEST = 702;

  public static readonly REQUIRED_BOT_CHALLENGE = 734;

  public static readonly MULTIPLE_PARTNERS_FOUND = 61916;

  public static readonly NO_FLOW_ASSIGNED = 25001;
}
