import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';
import { IAppState } from '../types';
import { checkShowField } from '../../common/fieldConditions';

export const currentStepNumber = (state: IAppState): number =>
  +state.form.current_step.replace('step_', '');

export const getNextStep = (
  state: IAppState
): DriverRegistrationPortalNoAuthService.DriverRegistrationLogLastStep => {
  const lastStepN = currentStepNumber(state);
  return `step_${
    lastStepN + 1
  }` as DriverRegistrationPortalNoAuthService.DriverRegistrationLogLastStep;
};

export const getPrevStep = (
  state: IAppState
): DriverRegistrationPortalNoAuthService.DriverRegistrationLogLastStep => {
  const lastStepN = currentStepNumber(state);
  return `step_${
    lastStepN - 1
  }` as DriverRegistrationPortalNoAuthService.DriverRegistrationLogLastStep;
};

export const canGoPrevStep = (state: IAppState): boolean => {
  const lastStepN = currentStepNumber(state);
  return lastStepN > 2;
};

export const isFormUploading = (state: IAppState): boolean =>
  state.form.uploading;

export const isFormLoading = (state: IAppState): boolean => state.form.loading;

export const getCurrentStep = (
  state: IAppState
): DriverRegistrationPortalNoAuthService.Step | undefined =>
  state.form.steps.find((s) => s.id === state.form.current_step);

export const getCurrentStepBanners = (
  state: IAppState
): DriverRegistrationPortalNoAuthService.Banner[] =>
  state.form.features.banners?.items?.find(
    (b) => b.step === state.form.current_step
  )?.list || [];

export const getCurrentStepName = (
  state: IAppState
): DriverRegistrationPortalNoAuthService.DriverRegistrationLogLastStep =>
  state.form.current_step;

export const getSteps = (
  state: IAppState
): DriverRegistrationPortalNoAuthService.Step[] => state.form.steps;

export const getFinalScreen = (
  state: IAppState
): DriverRegistrationPortalNoAuthService.FinalScreen | undefined =>
  state.form.final_screen;

export const getHash = (state: IAppState): string => state.form.hash;

export const getTranslations = (
  state: IAppState
): DriverRegistrationPortalNoAuthService.CommonTranslations =>
  state.localization.common_translations;

export const getRegistrationStatus = (
  state: IAppState
): DriverRegistrationPortalNoAuthService.DriverRegistrationLogStatus =>
  state.form.driver_registration_log_status;

export const getDeclineReasons = (state: IAppState): string[] =>
  state.form.decline_reasons ?? [];

export const isDocumentResubmission = (state: IAppState): boolean =>
  state.form.driver_registration_log_status ===
  DriverRegistrationPortalNoAuthService.DriverRegistrationLogStatus
    .DOCUMENTS_NEED_RESUBMISSION;

export const getCity = (state: IAppState): string | undefined =>
  state.form.city;

export const getCountryCode = (state: IAppState): string | undefined =>
  state.form.country;

export const getPrevNonEmptyStep = (
  state: IAppState
): DriverRegistrationPortalNoAuthService.DriverRegistrationLogLastStep | null => {
  const steps = getSteps(state);
  let searchStepNum = currentStepNumber(state) - 1;
  if (searchStepNum < 2) {
    return null;
  }
  do {
    const searchStepName = `step_${searchStepNum}`;
    const prevStep = state.form.steps.find((s) => s.id === searchStepName);
    const stepFields = prevStep?.fields.filter((field) =>
      checkShowField(field, steps)
    );
    if (stepFields && stepFields.length > 0) {
      return searchStepName as DriverRegistrationPortalNoAuthService.DriverRegistrationLogLastStep;
    }
    searchStepNum -= 1;
  } while (searchStepNum > 1);
  return null;
};

export const getCurrentStepFieldValue = (
  state: IAppState,
  fieldName: string
): any => {
  const currentStep = state.form.steps.find(
    (s) => s.id === state.form.current_step
  );
  if (!currentStep) {
    return undefined;
  }

  return currentStep.fields.find((f) => f.name === fieldName)?.current_value;
};

export const useEnhancedDocumentCapture = (state: IAppState): boolean =>
  state.form.use_enhanced_capture;

export const isDocumentStatusPollingEnabled = (state: IAppState): boolean =>
  state.form.is_document_statuses_polling_enabled;

export const enhancedCaptureDocumentKeys = (state: IAppState): string[] =>
  state.form.enhanced_capture_document_keys ?? [];

export const isAutosaveEnabled = (state: IAppState): boolean =>
  state.form.features.autosave?.enabled ?? false;
