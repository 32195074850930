import { Action, ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { getCity, getHash, isFormLoading } from '../form/formSelectors';
import { IAppState, ThunkMiddlware } from '../types';
import { Mixpanel, MixpanelMode } from '../../common/mixpanel';
import { mixpanelLoaded } from './mixpanelActions';
import { getCountry } from '../localization/localizationSelectors';

export const loadMixpanel: ActionCreator<
  ThunkAction<void, IAppState, ThunkMiddlware, Action<void>>
> = () => async (dispatch: Dispatch<any>, getState: () => IAppState) => {
  const state = getState();
  const hash = getHash(state);

  if (!hash || isFormLoading(state)) return;

  const country = getCountry(state);
  const city = getCity(state);

  const instance = new Mixpanel(hash, country, city, MixpanelMode.DEBUG);
  instance.identify();
  window.addEventListener('beforeunload', () => instance.reset());
  dispatch(mixpanelLoaded(instance));
};
