var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
//!!!! WARNING !!!!!!!!
//This is an auto generated file.
//Changes to this code will be lost if the code is regenerated.
//Generated by: TsAppClientApiPrinter
//Contact #dev-ui-platform-support in case of issues
import { GeneratedApiClient } from "../ApiClient";
import { HttpMethod } from "../HttpClient";
/* tslint:disable:no-namespace */
export var FleetRegistrationNoAuthService;
(function (FleetRegistrationNoAuthService) {
    var ApiClientClass = /** @class */ (function (_super) {
        __extends(ApiClientClass, _super);
        function ApiClientClass() {
            var _this = _super !== null && _super.apply(this, arguments) || this;
            /** Generated from: {@link [DriverRegistrationController.ts](https://github.com/bolteu/bolt-server/blob/0e1282fc2a168ada7ab834aee2f66210b72b1c02/src/rides/driver/fleet/main/registration/controller/DriverRegistrationController.ts)} */
            _this.checkRegistration = function (body, opts) { return __awaiter(_this, void 0, void 0, function () {
                var _a, _b;
                return __generator(this, function (_c) {
                    return [2 /*return*/, this.httpClient.sendRequest({
                            path: "/driverPortal/checkRegistration",
                            service_id: "fleet-registration",
                            subdomain: "driverportal",
                            http_method: HttpMethod.POST,
                            meta: {
                                auth: { enabled: false },
                                sharding: { enabled: false },
                                virtualPaths: { enabled: (_b = (_a = this.apiClientOptions) === null || _a === void 0 ? void 0 : _a.virtualPathsEnabled) !== null && _b !== void 0 ? _b : true }
                            },
                            body: body
                        }, opts)];
                });
            }); };
            /** Generated from: {@link [DriverRegistrationController.ts](https://github.com/bolteu/bolt-server/blob/0e1282fc2a168ada7ab834aee2f66210b72b1c02/src/rides/driver/fleet/main/registration/controller/DriverRegistrationController.ts)} */
            _this.getSubmittedDriverRegistrationLog = function (query, opts) { return __awaiter(_this, void 0, void 0, function () {
                var _a, _b;
                return __generator(this, function (_c) {
                    return [2 /*return*/, this.httpClient.sendRequest({
                            path: "/driverPortal/getSubmittedDriverRegistrationLog",
                            service_id: "fleet-registration",
                            subdomain: "driverportal",
                            http_method: HttpMethod.GET,
                            meta: {
                                auth: { enabled: false },
                                sharding: { enabled: false },
                                virtualPaths: { enabled: (_b = (_a = this.apiClientOptions) === null || _a === void 0 ? void 0 : _a.virtualPathsEnabled) !== null && _b !== void 0 ? _b : true }
                            },
                            query: query
                        }, opts)];
                });
            }); };
            /** Generated from: {@link [DriverRegistrationController.ts](https://github.com/bolteu/bolt-server/blob/0e1282fc2a168ada7ab834aee2f66210b72b1c02/src/rides/driver/fleet/main/registration/controller/DriverRegistrationController.ts)} */
            _this.getDriverRegistrationLog = function (query, opts) { return __awaiter(_this, void 0, void 0, function () {
                var _a, _b;
                return __generator(this, function (_c) {
                    return [2 /*return*/, this.httpClient.sendRequest({
                            path: "/driverPortal/getDriverRegistrationLog",
                            service_id: "fleet-registration",
                            subdomain: "driverportal",
                            http_method: HttpMethod.GET,
                            meta: {
                                auth: { enabled: false },
                                sharding: { enabled: false },
                                virtualPaths: { enabled: (_b = (_a = this.apiClientOptions) === null || _a === void 0 ? void 0 : _a.virtualPathsEnabled) !== null && _b !== void 0 ? _b : true }
                            },
                            query: query
                        }, opts)];
                });
            }); };
            /** Generated from: {@link [DriverRegistrationDocumentController.ts](https://github.com/bolteu/bolt-server/blob/0e1282fc2a168ada7ab834aee2f66210b72b1c02/src/rides/driver/fleet/main/registration/controller/DriverRegistrationDocumentController.ts)} */
            _this.getDriverRegistrationDocumentsSet = function (query, opts) { return __awaiter(_this, void 0, void 0, function () {
                var _a, _b;
                return __generator(this, function (_c) {
                    return [2 /*return*/, this.httpClient.sendRequest({
                            path: "/driverPortal/getDriverRegistrationDocumentsSet",
                            service_id: "fleet-registration",
                            subdomain: "driverportal",
                            http_method: HttpMethod.GET,
                            meta: {
                                auth: { enabled: false },
                                sharding: { enabled: false },
                                virtualPaths: { enabled: (_b = (_a = this.apiClientOptions) === null || _a === void 0 ? void 0 : _a.virtualPathsEnabled) !== null && _b !== void 0 ? _b : true }
                            },
                            query: query
                        }, opts)];
                });
            }); };
            /** Generated from: {@link [DriverRegistrationDocumentController.ts](https://github.com/bolteu/bolt-server/blob/0e1282fc2a168ada7ab834aee2f66210b72b1c02/src/rides/driver/fleet/main/registration/controller/DriverRegistrationDocumentController.ts)} */
            _this.getDriverRegistrationDocumentUrl = function (query, opts) { return __awaiter(_this, void 0, void 0, function () {
                var _a, _b;
                return __generator(this, function (_c) {
                    return [2 /*return*/, this.httpClient.sendRequest({
                            path: "/driverPortal/getDriverRegistrationDocumentUrl",
                            service_id: "fleet-registration",
                            subdomain: "driverportal",
                            http_method: HttpMethod.GET,
                            meta: {
                                auth: { enabled: false },
                                sharding: { enabled: false },
                                virtualPaths: { enabled: (_b = (_a = this.apiClientOptions) === null || _a === void 0 ? void 0 : _a.virtualPathsEnabled) !== null && _b !== void 0 ? _b : true }
                            },
                            query: query
                        }, opts)];
                });
            }); };
            /** Generated from: {@link [DriverRegistrationDocumentController.ts](https://github.com/bolteu/bolt-server/blob/0e1282fc2a168ada7ab834aee2f66210b72b1c02/src/rides/driver/fleet/main/registration/controller/DriverRegistrationDocumentController.ts)} */
            _this.uploadDriverRegistrationDocument = function (body, files, opts) { return __awaiter(_this, void 0, void 0, function () {
                var _a, _b;
                return __generator(this, function (_c) {
                    return [2 /*return*/, this.httpClient.sendRequest({
                            path: "/driverPortal/uploadDriverRegistrationDocument",
                            service_id: "fleet-registration",
                            subdomain: "driverportal",
                            http_method: HttpMethod.POST,
                            meta: {
                                auth: { enabled: false },
                                sharding: { enabled: false },
                                virtualPaths: { enabled: (_b = (_a = this.apiClientOptions) === null || _a === void 0 ? void 0 : _a.virtualPathsEnabled) !== null && _b !== void 0 ? _b : true }
                            },
                            body: body,
                            files: files
                        }, opts)];
                });
            }); };
            /** Generated from: {@link [DriverRegistrationDocumentController.ts](https://github.com/bolteu/bolt-server/blob/0e1282fc2a168ada7ab834aee2f66210b72b1c02/src/rides/driver/fleet/main/registration/controller/DriverRegistrationDocumentController.ts)} */
            _this.deleteDriverRegistrationDocument = function (body, opts) { return __awaiter(_this, void 0, void 0, function () {
                var _a, _b;
                return __generator(this, function (_c) {
                    return [2 /*return*/, this.httpClient.sendRequest({
                            path: "/driverPortal/deleteDriverRegistrationDocument",
                            service_id: "fleet-registration",
                            subdomain: "driverportal",
                            http_method: HttpMethod.POST,
                            meta: {
                                auth: { enabled: false },
                                sharding: { enabled: false },
                                virtualPaths: { enabled: (_b = (_a = this.apiClientOptions) === null || _a === void 0 ? void 0 : _a.virtualPathsEnabled) !== null && _b !== void 0 ? _b : true }
                            },
                            body: body
                        }, opts)];
                });
            }); };
            /** Generated from: {@link [DriverRegistrationController.ts](https://github.com/bolteu/bolt-server/blob/0e1282fc2a168ada7ab834aee2f66210b72b1c02/src/rides/driver/fleet/main/registration/controller/DriverRegistrationController.ts)} */
            _this.getDriverRegistrationFormData = function (query, opts) { return __awaiter(_this, void 0, void 0, function () {
                var _a, _b;
                return __generator(this, function (_c) {
                    return [2 /*return*/, this.httpClient.sendRequest({
                            path: "/driverPortal/getDriverRegistrationFormData",
                            service_id: "fleet-registration",
                            subdomain: "driverportal",
                            http_method: HttpMethod.GET,
                            meta: {
                                auth: { enabled: false },
                                sharding: { enabled: false },
                                virtualPaths: { enabled: (_b = (_a = this.apiClientOptions) === null || _a === void 0 ? void 0 : _a.virtualPathsEnabled) !== null && _b !== void 0 ? _b : true }
                            },
                            query: query
                        }, opts)];
                });
            }); };
            /** Generated from: {@link [DriverRegistrationController.ts](https://github.com/bolteu/bolt-server/blob/0e1282fc2a168ada7ab834aee2f66210b72b1c02/src/rides/driver/fleet/main/registration/controller/DriverRegistrationController.ts)} */
            _this.getDriverRegistrationFormFields = function (query, opts) { return __awaiter(_this, void 0, void 0, function () {
                var _a, _b;
                return __generator(this, function (_c) {
                    return [2 /*return*/, this.httpClient.sendRequest({
                            path: "/driverPortal/getDriverRegistrationFormFields",
                            service_id: "fleet-registration",
                            subdomain: "driverportal",
                            http_method: HttpMethod.GET,
                            meta: {
                                auth: { enabled: false },
                                sharding: { enabled: false },
                                virtualPaths: { enabled: (_b = (_a = this.apiClientOptions) === null || _a === void 0 ? void 0 : _a.virtualPathsEnabled) !== null && _b !== void 0 ? _b : true }
                            },
                            query: query
                        }, opts)];
                });
            }); };
            /** Generated from: {@link [DriverRegistrationController.ts](https://github.com/bolteu/bolt-server/blob/0e1282fc2a168ada7ab834aee2f66210b72b1c02/src/rides/driver/fleet/main/registration/controller/DriverRegistrationController.ts)} */
            _this.getDriverRegistrationPaymentFormData = function (query, opts) { return __awaiter(_this, void 0, void 0, function () {
                var _a, _b;
                return __generator(this, function (_c) {
                    return [2 /*return*/, this.httpClient.sendRequest({
                            path: "/driverPortal/getDriverRegistrationPaymentFormData",
                            service_id: "fleet-registration",
                            subdomain: "driverportal",
                            http_method: HttpMethod.GET,
                            meta: {
                                auth: { enabled: false },
                                sharding: { enabled: false },
                                virtualPaths: { enabled: (_b = (_a = this.apiClientOptions) === null || _a === void 0 ? void 0 : _a.virtualPathsEnabled) !== null && _b !== void 0 ? _b : true }
                            },
                            query: query
                        }, opts)];
                });
            }); };
            /** Generated from: {@link [DriverRegistrationController.ts](https://github.com/bolteu/bolt-server/blob/0e1282fc2a168ada7ab834aee2f66210b72b1c02/src/rides/driver/fleet/main/registration/controller/DriverRegistrationController.ts)} */
            _this.getDriverRegistrationCarModels = function (query, opts) { return __awaiter(_this, void 0, void 0, function () {
                var _a, _b;
                return __generator(this, function (_c) {
                    return [2 /*return*/, this.httpClient.sendRequest({
                            path: "/driverPortal/getDriverRegistrationCarModels",
                            service_id: "fleet-registration",
                            subdomain: "driverportal",
                            http_method: HttpMethod.GET,
                            meta: {
                                auth: { enabled: false },
                                sharding: { enabled: false },
                                virtualPaths: { enabled: (_b = (_a = this.apiClientOptions) === null || _a === void 0 ? void 0 : _a.virtualPathsEnabled) !== null && _b !== void 0 ? _b : true }
                            },
                            query: query
                        }, opts)];
                });
            }); };
            /** Generated from: {@link [DriverRegistrationController.ts](https://github.com/bolteu/bolt-server/blob/0e1282fc2a168ada7ab834aee2f66210b72b1c02/src/rides/driver/fleet/main/registration/controller/DriverRegistrationController.ts)} */
            _this.resubmitDriverRegistrationDocuments = function (body, opts) { return __awaiter(_this, void 0, void 0, function () {
                var _a, _b;
                return __generator(this, function (_c) {
                    return [2 /*return*/, this.httpClient.sendRequest({
                            path: "/driverPortal/resubmitDriverRegistrationDocuments",
                            service_id: "fleet-registration",
                            subdomain: "driverportal",
                            http_method: HttpMethod.POST,
                            meta: {
                                auth: { enabled: false },
                                sharding: { enabled: false },
                                virtualPaths: { enabled: (_b = (_a = this.apiClientOptions) === null || _a === void 0 ? void 0 : _a.virtualPathsEnabled) !== null && _b !== void 0 ? _b : true }
                            },
                            body: body
                        }, opts)];
                });
            }); };
            /** Generated from: {@link [DriverRegistrationController.ts](https://github.com/bolteu/bolt-server/blob/0e1282fc2a168ada7ab834aee2f66210b72b1c02/src/rides/driver/fleet/main/registration/controller/DriverRegistrationController.ts)} */
            _this.register = function (query, body, opts) { return __awaiter(_this, void 0, void 0, function () {
                var _a, _b;
                return __generator(this, function (_c) {
                    return [2 /*return*/, this.httpClient.sendRequest({
                            path: "/driverPortal/register",
                            service_id: "fleet-registration",
                            subdomain: "driverportal",
                            http_method: HttpMethod.POST,
                            meta: {
                                auth: { enabled: false },
                                sharding: { enabled: false },
                                virtualPaths: { enabled: (_b = (_a = this.apiClientOptions) === null || _a === void 0 ? void 0 : _a.virtualPathsEnabled) !== null && _b !== void 0 ? _b : true }
                            },
                            query: query,
                            body: body
                        }, opts)];
                });
            }); };
            return _this;
        }
        return ApiClientClass;
    }(GeneratedApiClient));
    // Split class actual prototype and type
    FleetRegistrationNoAuthService.ApiClient = ApiClientClass;
    /** Generated from: {@link [BrandHelper.ts](https://github.com/bolteu/bolt-server/blob/0e1282fc2a168ada7ab834aee2f66210b72b1c02/common/util/brand/BrandHelper.ts)} */
    var BrandEnum;
    (function (BrandEnum) {
        BrandEnum["BOLT"] = "bolt";
        BrandEnum["HOPP"] = "hopp";
    })(BrandEnum = FleetRegistrationNoAuthService.BrandEnum || (FleetRegistrationNoAuthService.BrandEnum = {}));
    /** Generated from: {@link [FleetAdminInternalAsyncApi.ts](https://github.com/bolteu/bolt-server/blob/0e1282fc2a168ada7ab834aee2f66210b72b1c02/asyncApi/fleet/FleetAdminInternalAsyncApi.ts)} */
    var CarModelBodyType;
    (function (CarModelBodyType) {
        CarModelBodyType["SEDAN"] = "sedan";
        CarModelBodyType["VAN"] = "van";
        CarModelBodyType["MINIVAN"] = "minivan";
        CarModelBodyType["WAGON"] = "wagon";
        CarModelBodyType["SUV"] = "suv";
        CarModelBodyType["HATCHBACK"] = "hatchback";
        CarModelBodyType["RICKSHAW"] = "rickshaw";
    })(CarModelBodyType = FleetRegistrationNoAuthService.CarModelBodyType || (FleetRegistrationNoAuthService.CarModelBodyType = {}));
    /** Generated from: {@link [FleetAdminInternalAsyncApi.ts](https://github.com/bolteu/bolt-server/blob/0e1282fc2a168ada7ab834aee2f66210b72b1c02/asyncApi/fleet/FleetAdminInternalAsyncApi.ts)} */
    var CarModelVehicleType;
    (function (CarModelVehicleType) {
        CarModelVehicleType["CAR"] = "car";
        CarModelVehicleType["MOTORBIKE"] = "motorbike";
    })(CarModelVehicleType = FleetRegistrationNoAuthService.CarModelVehicleType || (FleetRegistrationNoAuthService.CarModelVehicleType = {}));
    /** Generated from: {@link [Countries.ts](https://github.com/bolteu/bolt-server/blob/0e1282fc2a168ada7ab834aee2f66210b72b1c02/src/rides/driver/fleet/main/registration/constants/Countries.ts)} */
    var Countries;
    (function (Countries) {
        Countries["AUSTRIA"] = "at";
        Countries["AUSTRALIA"] = "au";
        Countries["AZERBAIJAN"] = "az";
        Countries["BELGIUM"] = "be";
        Countries["BULGARIA"] = "bg";
        Countries["CROATIA"] = "hr";
        Countries["CYPRUS"] = "cy";
        Countries["CZECH_REPUBLIC"] = "cz";
        Countries["DENMARK"] = "dk";
        Countries["ESTONIA"] = "ee";
        Countries["FINLAND"] = "fi";
        Countries["FRANCE"] = "fr";
        Countries["GERMANY"] = "de";
        Countries["GEORGIA"] = "ge";
        Countries["GHANA"] = "gh";
        Countries["GREECE"] = "gr";
        Countries["HUNGARY"] = "hu";
        Countries["IRELAND"] = "ie";
        Countries["ITALY"] = "it";
        Countries["KAZAKHSTAN"] = "kz";
        Countries["KENYA"] = "ke";
        Countries["LATVIA"] = "lv";
        Countries["LITHUANIA"] = "lt";
        Countries["LUXEMBOURG"] = "lu";
        Countries["MALTA"] = "mt";
        Countries["MEXICO"] = "mx";
        Countries["NETHERLANDS"] = "nl";
        Countries["NIGERIA"] = "ng";
        Countries["NORWAY"] = "no";
        Countries["POLAND"] = "pl";
        Countries["PORTUGAL"] = "pt";
        Countries["ROMANIA"] = "ro";
        Countries["RUSSIA"] = "ru";
        Countries["SLOVAKIA"] = "sk";
        Countries["SLOVENIA"] = "si";
        Countries["SPAIN"] = "es";
        Countries["SWEDEN"] = "se";
        Countries["TUNISIA"] = "tn";
        Countries["UNITED_KINGDOM"] = "gb";
        Countries["UKRAINE"] = "ua";
        Countries["BELARUS"] = "by";
        Countries["SOUTH_AFRICA"] = "za";
        Countries["ARMENIA"] = "am";
        Countries["TANZANIA"] = "tz";
    })(Countries = FleetRegistrationNoAuthService.Countries || (FleetRegistrationNoAuthService.Countries = {}));
    /** Generated from: {@link [DriverRegistrationTable.ts](https://github.com/bolteu/bolt-server/blob/0e1282fc2a168ada7ab834aee2f66210b72b1c02/src/rides/driver/fleet/main/registration/table/DriverRegistrationTable.ts)} */
    var DriverRegistrationLogLastStep;
    (function (DriverRegistrationLogLastStep) {
        DriverRegistrationLogLastStep["STEP1"] = "step_1";
        DriverRegistrationLogLastStep["STEP2"] = "step_2";
        DriverRegistrationLogLastStep["STEP3"] = "step_3";
        DriverRegistrationLogLastStep["STEP4"] = "step_4";
        DriverRegistrationLogLastStep["STEP5"] = "step_5";
        DriverRegistrationLogLastStep["STEP6"] = "step_6";
    })(DriverRegistrationLogLastStep = FleetRegistrationNoAuthService.DriverRegistrationLogLastStep || (FleetRegistrationNoAuthService.DriverRegistrationLogLastStep = {}));
    /** Generated from: {@link [DriverRegistrationTable.ts](https://github.com/bolteu/bolt-server/blob/0e1282fc2a168ada7ab834aee2f66210b72b1c02/src/rides/driver/fleet/main/registration/table/DriverRegistrationTable.ts)} */
    var DriverRegistrationLogLicenseTypeSelection;
    (function (DriverRegistrationLogLicenseTypeSelection) {
        DriverRegistrationLogLicenseTypeSelection["PRIVATE_DRIVER"] = "private_driver";
        DriverRegistrationLogLicenseTypeSelection["TAXI_DRIVER"] = "taxi_driver";
        DriverRegistrationLogLicenseTypeSelection["UNKNOWN"] = "unknown";
    })(DriverRegistrationLogLicenseTypeSelection = FleetRegistrationNoAuthService.DriverRegistrationLogLicenseTypeSelection || (FleetRegistrationNoAuthService.DriverRegistrationLogLicenseTypeSelection = {}));
    /** Generated from: {@link [DriverRegistrationTable.ts](https://github.com/bolteu/bolt-server/blob/0e1282fc2a168ada7ab834aee2f66210b72b1c02/src/rides/driver/fleet/main/registration/table/DriverRegistrationTable.ts)} */
    var DriverRegistrationLogSource;
    (function (DriverRegistrationLogSource) {
        DriverRegistrationLogSource["DRIVER_PORTAL"] = "driver_portal";
        DriverRegistrationLogSource["FLEET_PORTAL"] = "fleet_owner_portal";
    })(DriverRegistrationLogSource = FleetRegistrationNoAuthService.DriverRegistrationLogSource || (FleetRegistrationNoAuthService.DriverRegistrationLogSource = {}));
    /** Generated from: {@link [Types.ts](https://github.com/bolteu/bolt-server/blob/0e1282fc2a168ada7ab834aee2f66210b72b1c02/common/driver/constants/Types.ts)} */
    var DriverRegistrationLogStatus;
    (function (DriverRegistrationLogStatus) {
        DriverRegistrationLogStatus["IN_PROGRESS"] = "in_progress";
        DriverRegistrationLogStatus["SUBMITTED"] = "submitted";
        DriverRegistrationLogStatus["ONBOARDING"] = "onboarding";
        DriverRegistrationLogStatus["DECLINED"] = "declined";
        DriverRegistrationLogStatus["DECLINED_SILENTLY"] = "declined_silently";
        DriverRegistrationLogStatus["ACCEPTED"] = "accepted";
        DriverRegistrationLogStatus["DOCUMENTS_NEED_RESUBMISSION"] = "documents_need_resubmission";
        DriverRegistrationLogStatus["DOCUMENTS_RESUBMITTED"] = "documents_resubmitted";
        DriverRegistrationLogStatus["DELETED"] = "deleted";
        DriverRegistrationLogStatus["PENDING_COMPANY_APPROVAL"] = "pending_company_approval";
    })(DriverRegistrationLogStatus = FleetRegistrationNoAuthService.DriverRegistrationLogStatus || (FleetRegistrationNoAuthService.DriverRegistrationLogStatus = {}));
    /** Generated from: {@link [DriverRegistrationTable.ts](https://github.com/bolteu/bolt-server/blob/0e1282fc2a168ada7ab834aee2f66210b72b1c02/src/rides/driver/fleet/main/registration/table/DriverRegistrationTable.ts)} */
    var DriverRegistrationLogType;
    (function (DriverRegistrationLogType) {
        DriverRegistrationLogType["COMPANY"] = "company";
        DriverRegistrationLogType["PERSON"] = "person";
        DriverRegistrationLogType["OTHER"] = "other";
    })(DriverRegistrationLogType = FleetRegistrationNoAuthService.DriverRegistrationLogType || (FleetRegistrationNoAuthService.DriverRegistrationLogType = {}));
    /** Generated from: {@link [registrationPaymentDetailsType.ts](https://github.com/bolteu/bolt-server/blob/0e1282fc2a168ada7ab834aee2f66210b72b1c02/src/rides/driver/fleet/main/registration/constants/registrationPaymentDetailsType.ts)} */
    var RegistrationPaymentDetailsType;
    (function (RegistrationPaymentDetailsType) {
        RegistrationPaymentDetailsType["COMPANY"] = "company";
        RegistrationPaymentDetailsType["PERSON"] = "person";
        RegistrationPaymentDetailsType["OTHER"] = "other";
    })(RegistrationPaymentDetailsType = FleetRegistrationNoAuthService.RegistrationPaymentDetailsType || (FleetRegistrationNoAuthService.RegistrationPaymentDetailsType = {}));
})(FleetRegistrationNoAuthService || (FleetRegistrationNoAuthService = {}));
