import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadMixpanel } from '../redux/mixpanel/mixpanelThunk';
import { Step } from './Step';
import { WizardProgress } from './WizardSteps';
import { NotificationsArea } from './NotificationsArea';
import {
  isDocumentResubmission,
  isFormLoading,
  getHash,
} from '../redux/form/formSelectors';
import { DocumentsResubmit } from './DocumentsResubmit';
import { ContinueInAppBanner } from './ContinueInAppBanner';
import { UpdateAppBanner } from './UpdateAppBanner';
import { getScriptDirection } from '../redux/localization/localizationSelectors';

export const Signup: React.FC = () => {
  const hash = useSelector(getHash);
  const isLoading = useSelector(isFormLoading);
  const scriptDirection = useSelector(getScriptDirection);

  const dispatch = useDispatch();
  const isResubmission = useSelector(isDocumentResubmission);

  React.useEffect(() => {
    document.documentElement.dir = scriptDirection;
  }, [scriptDirection]);

  React.useEffect(() => {
    dispatch(loadMixpanel());
  }, [dispatch, isLoading, hash]);

  if (isResubmission) {
    return (
      <div>
        <DocumentsResubmit hash={hash} />
      </div>
    );
  }

  return (
    <div>
      <WizardProgress />
      <NotificationsArea />
      <ContinueInAppBanner />
      <UpdateAppBanner />
      <Step hash={hash} />
    </div>
  );
};
