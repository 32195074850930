import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';
import { Button } from '../Button';
import qrBolt from '../../assets/qr-bolt-driver.png';
import qrHopp from '../../assets/qr-hopp-driver.png';
import { DeviceData } from '../../common/deviceData';
import { getTranslations } from '../../redux/form/formSelectors';
import { ButtonLink } from '../ButtonLink';
import {
  getAppBrand,
  getLinkToAppStore,
  getLinkToHuaweiStore,
} from '../../common/environment';
import { continueInBrowser } from '../../redux/verification/verificationThunk';
import { getCreateAccountResult } from '../../redux/verification/verificationSelectors';

export const AppPromotion: React.FC = () => {
  const dispatch = useDispatch();

  const commonTranslations = useSelector(getTranslations);
  const createAccountResult = useSelector(getCreateAccountResult);

  const androidLink = getLinkToAppStore();
  const iosLink = getLinkToAppStore();
  const huaweiLink = getLinkToHuaweiStore();

  const onContinueInBrowser = () => {
    dispatch(continueInBrowser());
  };

  const qrCodeSrc = getAppBrand() === 'hopp' ? qrHopp : qrBolt;
  const isRequired =
    createAccountResult?.app_promotion ===
    DriverRegistrationPortalNoAuthService.AppPromotionMode.REQUIRED;

  return (
    <div>
      <div className="pb-8 px-2">
        <div className="text-center p-2 mx-auto">
          <h1 className="text-2xl font-bold mb-4">
            {commonTranslations.app_promotion.get_driver_app}
          </h1>
          <div className="text-gray-700 mt-1">
            {commonTranslations.app_promotion.please_download_app_text}
          </div>
          <div className="text-gray-700 mb-4">
            {commonTranslations.app_promotion.you_can_login}
          </div>
          {DeviceData.isMobileBrowser() ? (
            <div className="w-full">
              <ButtonLink
                href={DeviceData.isAndroid() ? androidLink : iosLink}
                target="_blank"
                color="bg-purple-500"
                label={
                  DeviceData.isAndroid()
                    ? commonTranslations.app_promotion.download_on_google_play
                    : commonTranslations.app_promotion.download_on_app_store
                }
                id="download_app"
              />
            </div>
          ) : (
            <div className="w-full text-gray-700 mb-4">
              {commonTranslations.app_promotion.scan_qr_code}
              <img
                src={qrCodeSrc}
                alt=""
                width={150}
                height={150}
                className="mx-auto mt-2"
              />
              <br />
              {commonTranslations.app_promotion.or_use_links} &nbsp;
              <a target="_blank" rel="noreferrer" href={androidLink}>
                Android
              </a>
              &nbsp; | &nbsp;
              <a target="_blank" rel="noreferrer" href={iosLink}>
                iOS
              </a>
              &nbsp; | &nbsp;
              <a target="_blank" rel="noreferrer" href={huaweiLink}>
                Huawei
              </a>
            </div>
          )}
          {!isRequired && (
            <div>
              <Button
                onClick={onContinueInBrowser}
                color="bg-gray-200"
                textColor="text-gray-900"
                label={commonTranslations.app_promotion.continue_in_browser}
                disabled={false}
                loading={false}
                id="continue_in_browser"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
