import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';
import { VerificationState } from '../types';
import { createReducer } from '../helper';
import {
  ActionType,
  SetCaptchaNeeded,
  SetCreateAccountResult,
  SetVerificationChannel,
  SetVerificationData,
} from './verificationActions';

const initialState: VerificationState = {
  available_verification_code_channels: [
    DriverRegistrationPortalNoAuthService.VerificationCodeChannel.SMS,
  ],
  resend_wait_time_seconds: 30,
  verification_code_channel:
    DriverRegistrationPortalNoAuthService.VerificationCodeChannel.SMS,
  verification_code_length: 4,
  verification_code_target: '',
  verification_loading: true,
  confirmation_loading: false,
  show_conflict_resolution: false,
  captcha_parameters: null,
  captcha_v3_site_key: null,
  country_is_known: false,
  create_account_result: null,
};

const setVerificationData = (
  state: VerificationState,
  action: SetVerificationData
) => {
  return {
    ...state,
    ...action.verification_data,
    verification_loading: false,
    show_conflict_resolution: false,
  };
};

const setVerificationChannel = (
  state: VerificationState,
  action: SetVerificationChannel
) => {
  return {
    ...state,
    verification_code_channel: action.verification_code_channel,
  };
};
const setVerificationLoading = (state: VerificationState) => ({
  ...state,
  verification_loading: true,
  show_conflict_resolution: false,
});

const setVerificationLoaded = (state: VerificationState) => ({
  ...state,
  verification_loading: false,
});

const setCountryIsKnown = (state: VerificationState) => ({
  ...state,
  country_is_known: true,
});

const setConfirmationLoading = (state: VerificationState) => ({
  ...state,
  confirmation_loading: true,
  show_conflict_resolution: false,
});

const setConfirmationLoaded = (state: VerificationState) => ({
  ...state,
  confirmation_loading: false,
});

const requireManualConflictResolution = (state: VerificationState) => ({
  ...state,
  show_conflict_resolution: true,
  confirmation_loading: false,
});

const setCaptchaNeeded = (
  state: VerificationState,
  action: SetCaptchaNeeded
) => {
  const newState = {
    ...state,
    captcha_parameters: action.captcha_parameters,
  };

  if (
    !state.captcha_v3_site_key &&
    action.captcha_parameters.type ===
      DriverRegistrationPortalNoAuthService.CaptchaChallengeType
        .RECAPTCHA_NATIVE
  ) {
    newState.captcha_v3_site_key = action.captcha_parameters.site_key;
  }

  return newState;
};

const setCaptchaNotNeeded = (state: VerificationState) => ({
  ...state,
  captcha_parameters: null,
});

const setCreateAccountResult = (
  state: VerificationState,
  action: SetCreateAccountResult
) => ({
  ...state,
  create_account_result: action.value,
});

const handlers = {
  [ActionType.SET_VERIFICATION_DATA]: setVerificationData,
  [ActionType.SET_VERIFICATION_CHANNEL]: setVerificationChannel,
  [ActionType.SET_VERIFICATION_LOADING]: setVerificationLoading,
  [ActionType.SET_VERIFICATION_LOADED]: setVerificationLoaded,
  [ActionType.SET_CONFIRMATION_LOADING]: setConfirmationLoading,
  [ActionType.SET_CONFIRMATION_LOADED]: setConfirmationLoaded,
  [ActionType.REQUIRE_MANUAL_CONFLICT_RESOLUTION]:
    requireManualConflictResolution,
  [ActionType.SET_CAPTCHA_NEEDED]: setCaptchaNeeded,
  [ActionType.SET_CAPTCHA_NOT_NEEDED]: setCaptchaNotNeeded,
  [ActionType.SET_COUNTRY_IS_KNOWN]: setCountryIsKnown,
  [ActionType.SET_CREATE_ACCOUNT_RESULT]: setCreateAccountResult,
};

export const verificationReducer = createReducer<VerificationState>(
  initialState,
  handlers
);
