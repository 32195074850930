import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';
import { CaptchaChallengeParameters, IAppState } from '../types';

export const getPhone = (state: IAppState): string =>
  state.verification.verification_code_target;

export const getResendWaitTime = (state: IAppState): number =>
  state.verification.resend_wait_time_seconds;

export const getCodeLength = (state: IAppState): number =>
  state.verification.verification_code_length;

export const getAvailableChannels = (
  state: IAppState
): DriverRegistrationPortalNoAuthService.VerificationCodeChannel[] =>
  state.verification.available_verification_code_channels;

export const getSelectedVerificationChannel = (
  state: IAppState
): DriverRegistrationPortalNoAuthService.VerificationCodeChannel =>
  state.verification.verification_code_channel;

export const isVerificationLoading = (state: IAppState): boolean =>
  state.verification.verification_loading;

export const isCountryIsKnown = (state: IAppState): boolean =>
  state.verification.country_is_known;

export const isConfirmationLoading = (state: IAppState): boolean =>
  state.verification.confirmation_loading;

export const isConflictResolutionDisplayed = (state: IAppState): boolean =>
  state.verification.show_conflict_resolution;

export const getCaptchaParameters = (
  state: IAppState
): CaptchaChallengeParameters | null => state.verification.captcha_parameters;

export const getCaptchaV3Key = (state: IAppState): string | null =>
  state.verification.captcha_v3_site_key;

export const getCreateAccountResult = (
  state: IAppState
): DriverRegistrationPortalNoAuthService.DriverProvisionalRegistrationCreateAccountResponse | null =>
  state.verification.create_account_result;
