import React from 'react';
import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';
import { Checkbox } from './input/Checkbox';
import { Textbox } from './input/Textbox';
import { DatePicker } from './input/DatePicker';
import { CascadeSelect } from './input/CascadeSelect';
import { Label } from './input/Label';
import { TaxIdentificationNumberField } from './input/TaxIdentificationNumberField';
import { isAutosaveEnabled } from '../redux/form/formSelectors';
import { useSelector } from '../redux/store';
import { VehicleColor } from './input/VehicleColor';

interface BaseFieldProps {
  fieldDefinition: DriverRegistrationPortalNoAuthService.Field;
  updateHandler: (data: {
    changed: DriverRegistrationPortalNoAuthService.Field['current_value'];
    autosaveEnabled: boolean;
  }) => void;
  blurHandler: (
    event: React.FocusEvent<HTMLInputElement, Element>,
    autosave: { enabled: boolean; always?: boolean }
  ) => void;
}

const mapNameToAutoComplete = (name: string): string | undefined => {
  switch (name) {
    case 'first_name':
      return 'given-name';
    case 'last_name':
      return 'family-name';
    default:
      return undefined;
  }
};

export const BaseField: React.FC<BaseFieldProps> = ({
  fieldDefinition,
  updateHandler,
  blurHandler,
}: BaseFieldProps): React.ReactElement | null => {
  const fieldValue = fieldDefinition.current_value;
  const autosaveEnabled = useSelector(isAutosaveEnabled);

  if (fieldDefinition.name === 'car_color') {
    return (
      <VehicleColor
        field={fieldDefinition}
        updateHandler={(v) => updateHandler({ changed: v, autosaveEnabled })}
        blurHandler={(e) => blurHandler(e, { enabled: autosaveEnabled })}
      />
    );
  }

  switch (fieldDefinition.type) {
    case DriverRegistrationPortalNoAuthService.FrontendFieldDefinitionType
      .CHECKBOX:
      return (
        <Checkbox
          title={fieldDefinition.translations.label}
          description={fieldDefinition.translations.description}
          value={!!fieldValue}
          updateHandler={(v) => updateHandler({ changed: v, autosaveEnabled })}
          blurHandler={(e) => blurHandler(e, { enabled: autosaveEnabled })}
        />
      );
    case DriverRegistrationPortalNoAuthService.FrontendFieldDefinitionType
      .INPUT:
      return (
        <Textbox
          title={fieldDefinition.translations.label}
          placeholder={fieldDefinition.translations.placeholder}
          description={fieldDefinition.translations.description}
          isRequired={fieldDefinition.is_required}
          value={fieldValue}
          fieldName={fieldDefinition.name}
          updateHandler={(v) => updateHandler({ changed: v, autosaveEnabled })}
          blurHandler={(e) => blurHandler(e, { enabled: autosaveEnabled })}
          autoComplete={mapNameToAutoComplete(fieldDefinition.name)}
        />
      );
    case DriverRegistrationPortalNoAuthService.FrontendFieldDefinitionType.DATE:
      return (
        <DatePicker
          title={fieldDefinition.translations.label}
          value={fieldValue as string}
          fieldName={fieldDefinition.name}
          updateHandler={(v) => updateHandler({ changed: v, autosaveEnabled })}
          blurHandler={(e) => blurHandler(e, { enabled: autosaveEnabled })}
        />
      );
    case DriverRegistrationPortalNoAuthService.FrontendFieldDefinitionType
      .SELECT:
    case DriverRegistrationPortalNoAuthService.FrontendFieldDefinitionType
      .GENERIC_DROPDOWN:
      return (
        <CascadeSelect
          field={fieldDefinition}
          updateHandler={(v) => updateHandler({ changed: v, autosaveEnabled })}
          blurHandler={(e) => blurHandler(e, { enabled: autosaveEnabled })}
        />
      );
    case DriverRegistrationPortalNoAuthService.FrontendFieldDefinitionType.TEXT:
      return <Label title={fieldDefinition.translations.label} />;
    case DriverRegistrationPortalNoAuthService.FrontendFieldDefinitionType
      .GENERIC_INPUT:
      return (
        <Textbox
          title={fieldDefinition.translations.label}
          placeholder={fieldDefinition.translations.placeholder}
          description={fieldDefinition.translations.description}
          isRequired={fieldDefinition.is_required}
          value={fieldValue}
          fieldName={fieldDefinition.generic_field_key ?? fieldDefinition.name}
          updateHandler={(v) => updateHandler({ changed: v, autosaveEnabled })}
          blurHandler={(e) => blurHandler(e, { enabled: autosaveEnabled })}
        />
      );
    case DriverRegistrationPortalNoAuthService.FrontendFieldDefinitionType
      .TAX_IDENTIFICATION_NUMBERS:
      return (
        <TaxIdentificationNumberField
          field={fieldDefinition}
          choices={fieldDefinition.select_values || []}
          title={fieldDefinition.translations.label}
          description={fieldDefinition.translations.description}
          value={fieldValue}
          updateHandler={(v) =>
            updateHandler({ changed: v, autosaveEnabled: false })
          }
          blurHandler={(e) =>
            blurHandler(e, { enabled: autosaveEnabled, always: true })
          }
        />
      );
    case DriverRegistrationPortalNoAuthService.FrontendFieldDefinitionType
      .HIDDEN:
    default:
      return null;
  }
};
