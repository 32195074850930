import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';
import {
  FieldValidationError,
  CommonError,
  VerificationError,
} from './notification/notificationActions';
import { Mixpanel } from '../common/mixpanel';
import {
  DocumentsApiClient,
  EventLogApiClient,
  RegistrationApiClient,
} from '../api/types/apiTypes';

export interface IAppState {
  form: FormState;
  notifications: NotificationsState;
  experiments: ExperimentsState;
  chat: ChatState;
  mixpanel: MixpanelState;
  auth: AuthState;
  autosave: AutosaveState;
  localization: LocalizationState;
  verification: VerificationState;
}

export enum LoadingStatus {
  LOADING = 'loading',
  READY = 'ready',
  FAILED = 'failed',
}

export type FormState = {
  steps: Step[];
  final_screen?: DriverRegistrationPortalNoAuthService.FinalScreen;
  current_step: DriverRegistrationPortalNoAuthService.DriverRegistrationLogLastStep;
  common_translations: DriverRegistrationPortalNoAuthService.CommonTranslations;
  language: string;
  languages: DriverRegistrationPortalNoAuthService.FlowLanguage[];
  driver_registration_log_status: DriverRegistrationPortalNoAuthService.DriverRegistrationLogStatus;
  decline_reasons?: string[];
  flow_id: number;
  uploading: boolean;
  loading: boolean;
  hash: string;
  country?: string;
  city?: string;
  use_enhanced_capture: boolean;
  enhanced_capture_document_keys?: string[];
  is_document_statuses_polling_enabled: boolean;
  features: DriverRegistrationPortalNoAuthService.GetSignupProcessFeaturesResponse;
};

export type Step = {
  id: DriverRegistrationPortalNoAuthService.DriverRegistrationLogLastStep;
  translations: DriverRegistrationPortalNoAuthService.StepTranslations;
  fields: ExtendedField[];
};

export interface ExtendedField
  extends DriverRegistrationPortalNoAuthService.Field {
  document_status?: DriverRegistrationPortalNoAuthService.DocumentResultStatus;
  document_error?: string;
}

export interface NotificationsState {
  fieldErrors: FieldValidationError[];
  commonErrors: CommonError[];
  verificationErrors: VerificationError[];
}

export interface Location {
  country?: string;
  city?: string;
}

export type ExperimentParameters =
  DriverRegistrationPortalNoAuthService.ExperimentParameters;
export interface ExperimentsState {
  [key: string]: DriverRegistrationPortalNoAuthService.ExperimentParameters;
}

export interface ChatState {
  loaded: boolean;
}

export interface MixpanelState {
  instance?: Mixpanel;
}

export interface ActualRegistrationInfo {
  hash: string;
  status: DriverRegistrationPortalNoAuthService.DriverRegistrationLogStatus;
  /**
   * @deprecated This was used to display message for application in not allowed status.
   * Now all such registrations are redirected to Driver Portal and displayed there.
   */
  statusErrorMessage: string | null;
}

export interface AuthState {
  loadingStatus: LoadingStatus;
  refreshToken: string | null;
  accessToken: string | null;
  actualRegistrationInfo: ActualRegistrationInfo | null;
  isRedirecting: boolean;
}

export enum AutosaveStatus {
  PENDING = 'pending',
  SAVING = 'saving',
  SUCCESSFUL = 'successful',
  FAILED = 'failed',
}

export interface AutosaveState {
  status: AutosaveStatus;
  timeout: NodeJS.Timeout | null;
  fieldName: string | null;
  fieldValue: any;
  featureEnabled: boolean;
}

export enum LanguageLoadingStatus {
  NOT_STARTED,
  LOADING,
  READY,
}

export interface LocalizationState {
  country: string;
  is_country_final: boolean;
  language: string;
  script_direction: DriverRegistrationPortalNoAuthService.ScriptDirection;
  languages: DriverRegistrationPortalNoAuthService.FlowLanguage[];
  common_translations: DriverRegistrationPortalNoAuthService.CommonTranslations;
  loading_status: LanguageLoadingStatus;
}

export type VerificationState =
  DriverRegistrationPortalNoAuthService.DriverProvisionalRegistrationStartVerificationResponse & {
    verification_loading: boolean;
    confirmation_loading: boolean;
    show_conflict_resolution: boolean;
    captcha_parameters: CaptchaChallengeParameters | null;
    captcha_v3_site_key: string | null;
    country_is_known: boolean;
    create_account_result: DriverRegistrationPortalNoAuthService.DriverProvisionalRegistrationCreateAccountResponse | null;
  };

export interface CaptchaChallengeParameters {
  site_key: string;
  type: DriverRegistrationPortalNoAuthService.CaptchaChallengeType;
}

export interface ThunkMiddlware {
  registrationApi: RegistrationApiClient;
  documentsApi: DocumentsApiClient;
  eventLogApi: EventLogApiClient;
}
