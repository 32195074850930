import React, { ReactElement } from 'react';
import { getAppBrand } from '../../common/environment';

const BOLT_LOGO: ReactElement = (
  <svg
    width="152"
    height="32"
    viewBox="0 0 152 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M63.393 24.29h7.775c6.426 0 11.632-5.108 11.632-11.406 0-6.297-5.206-11.406-11.632-11.406h-7.775v22.813Zm5.109-4.69V6.17h2.666c3.856 0 6.459 3.02 6.459 6.714 0 3.695-2.603 6.716-6.459 6.716h-2.666Zm16.548 4.69h4.852v-7.614c0-2.314 1.156-3.92 3.245-3.92.77 0 1.413.128 2.152.385V8.225a5.83 5.83 0 0 0-1.574-.192c-1.8 0-3.31 1.188-3.823 2.602v-2.41H85.05v16.066ZM99.776 6.04c1.703 0 3.052-1.349 3.052-3.02 0-1.67-1.349-3.02-3.052-3.02-1.639 0-3.02 1.35-3.02 3.02 0 1.671 1.381 3.02 3.02 3.02Zm-2.41 18.25h4.852V8.226h-4.852v16.066Zm13.218 0h4.499l7.036-16.065h-5.398l-3.92 9.768-3.888-9.768h-5.365l7.036 16.066Zm19.876.354c3.374 0 6.041-1.381 7.551-3.534l-3.599-2.7c-.61.933-1.992 1.832-3.888 1.832-1.831 0-3.63-.9-3.92-2.827h11.664c.096-.771.096-1.285.096-1.703 0-5.205-3.759-7.84-8-7.84-4.884 0-8.451 3.63-8.451 8.386 0 5.173 3.824 8.386 8.547 8.386Zm-3.727-10.41c.418-1.767 2.088-2.41 3.502-2.41 1.414 0 2.892.707 3.31 2.41h-6.812Zm14.046 10.056h4.851v-7.614c0-2.314 1.157-3.92 3.246-3.92.771 0 1.413.128 2.152.385V8.225a5.824 5.824 0 0 0-1.574-.192c-1.799 0-3.31 1.188-3.824 2.602v-2.41h-4.851v16.066Z"
      fill="#2A313C"
    />
    <path
      d="M15.763 11.787A7.266 7.266 0 0 0 9.586.695H.972v23.537h9.691a7.268 7.268 0 0 0 5.1-12.445ZM6.51 6.233h3.076c.954 0 1.73.777 1.73 1.73 0 .955-.776 1.731-1.73 1.731H6.51v-3.46Zm4.153 12.46H6.51v-3.46h4.153c.954 0 1.73.776 1.73 1.73s-.776 1.73-1.73 1.73ZM44.698.696v23.537H39.16V1.872L44.698.695ZM28.546 7.38a8.546 8.546 0 1 0 8.545 8.546c-.007-4.715-3.83-8.546-8.545-8.546Zm0 11.315a2.767 2.767 0 0 1-2.769-2.77 2.767 2.767 0 0 1 2.769-2.768 2.767 2.767 0 0 1 2.769 2.769 2.767 2.767 0 0 1-2.77 2.769Zm2.769 10.537a2.769 2.769 0 1 1-5.538 0 2.769 2.769 0 0 1 5.538 0ZM55.313 7.695v5.53H52.55v4.353c0 1.316.423 2.285 1.53 2.285.424.007.84-.046 1.24-.162v4.077a5.618 5.618 0 0 1-2.7.692h-.07c-.035 0-.07-.002-.105-.004-.033-.002-.065-.004-.095-.004h-.053l-.1-.007c-3.093-.162-5.192-2.108-5.192-5.484V4.642l5.538-1.178v4.23h2.769Z"
      fill="#5B68F6"
    />
  </svg>
);

const HOPP_LOGO: ReactElement = (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="67"
    height="32"
    viewBox="0 0 392 188"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,188.000000) scale(0.100000,-0.100000)"
      fill="#000000"
      stroke="none"
    >
      <path
        d="M288 975 l2 -496 133 3 132 3 3 178 2 177 155 0 155 0 0 -180 0 -181
133 3 132 3 3 493 2 492 -135 0 -135 0 -2 -177 -3 -178 -150 0 -150 0 -3 178
-2 177 -138 0 -137 0 3 -495z"
      />
      <path
        d="M1455 1195 c-69 -26 -87 -36 -144 -85 -28 -24 -86 -121 -99 -166 -15
-52 -16 -160 -1 -205 36 -114 92 -179 199 -232 60 -30 72 -32 165 -32 87 0
107 3 156 26 234 110 289 423 106 603 -74 72 -142 100 -252 103 -60 2 -103 -2
-130 -12z m158 -239 c47 -19 67 -55 67 -121 0 -49 -4 -60 -29 -86 -63 -62
-174 -24 -188 64 -10 62 13 113 61 137 49 24 48 24 89 6z"
      />
      <path
        d="M2332 1201 c-7 -4 -80 -6 -161 -3 -99 3 -150 1 -155 -6 -3 -6 -6
-228 -6 -494 l0 -483 130 -3 130 -3 0 146 c0 80 2 145 4 145 2 0 23 -7 46 -15
67 -24 149 -19 219 11 34 15 61 31 61 35 0 5 5 9 11 9 6 0 16 6 24 13 25 25
82 122 96 162 16 48 18 213 3 247 -6 12 -17 37 -25 53 -37 82 -84 129 -166
166 -50 23 -77 29 -130 29 -38 0 -74 -4 -81 -9z m107 -256 c108 -92 21 -270
-111 -226 -90 30 -99 197 -13 240 38 19 92 13 124 -14z"
      />
      <path
        d="M3113 1195 c-19 -8 -35 -10 -38 -4 -4 5 -64 9 -136 9 l-129 0 0 -495
0 -495 130 0 130 0 0 138 c0 76 3 141 6 145 3 3 24 -1 46 -9 142 -50 309 24
380 170 38 77 48 116 48 188 0 148 -83 286 -206 341 -70 31 -175 37 -231 12z
m110 -236 c32 -14 67 -78 67 -120 0 -40 -29 -91 -65 -115 -39 -25 -103 -13
-137 26 -24 25 -28 39 -28 86 0 61 20 106 54 123 28 13 80 13 109 0z"
      />
    </g>
  </svg>
);

export const Logo: React.FC = (): ReactElement => {
  const logo = getAppBrand() === 'hopp' ? HOPP_LOGO : BOLT_LOGO;
  return (
    <div className="clearfix">
      <div className="flex flex-row justify-center mt-5 mb-6 pb-3 border-b border-gray-300">
        {logo}
      </div>
    </div>
  );
};
